import * as React from "react";
import styled from "styled-components";

import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import SendIcon from "@material-ui/icons/Send";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import { device } from "./styled/sitewide";

const entries = [
  { icon: <PhoneInTalkIcon></PhoneInTalkIcon>, value: "(410) 891-4050" },
  { icon: <SendIcon></SendIcon>, value: "contact@techslice.com" },
  { icon: <LocationCityIcon></LocationCityIcon>, value: "Baltimore, MD" },
];

const Prompt = styled.h3`
  font-size: 1.75rem;
  margin-bottom: 2rem;
`;

const ContactEntries = styled.ul`
  display: grid;
  column-gap: 2rem;
  row-gap: 1rem;
  grid-template-columns: 1fr;
  list-style-type: none;
  padding-left: 0;

  @media ${device.mobile} {
    grid-template-columns: repeat(2, minmax(0, max-content));
  }
`;

const ContactEntry = styled.li`
  align-items: center;
  display: flex;
  gap: 0.75rem;

  svg {
    background-color: var(--primary);
    border-radius: 1rem;
    box-sizing: border-box;
    color: var(--text-over-primary);
    height: 2rem;
    padding: 6px;
    width: 2rem;
  }
`;

const ContactInformation = () => {
  return (
    <div>
      <Prompt>Contact Information</Prompt>
      <ContactEntries>
        {entries.map((e, i) => (
          <ContactEntry key={i}>
            {e.icon}
            <span>{e.value}</span>
          </ContactEntry>
        ))}
      </ContactEntries>
    </div>
  );
};
export default ContactInformation;
