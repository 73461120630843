import * as React from "react";
import Layout from "src/components/structure/layout";
import PageHeading from "src/components/page-heading";
import ContactInformation from "src/components/contact-information";
import SendUsAMessage from "src/components/send-us-a-message";

const ContactPage = () => {
  return (
    <Layout pageTitle="Contact">
      <PageHeading
        title="Contact"
        image="workspace"
        crumbs={["TechSlice", "Contact"]}
      >
        Contact
      </PageHeading>
      <div
        className="container"
        style={{
          maxWidth: 800,
          marginBottom: "4rem",
        }}
      >
        <h2 style={{ color: "var(--strong)", fontSize: "2rem", textAlign: "center", margin: 0 }}>
          We love to talk tech.
        </h2>

        <ContactInformation></ContactInformation>
        <SendUsAMessage></SendUsAMessage>
      </div>
    </Layout>
  );
};

export default ContactPage;
